import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image"

const OurTeamSection = () => {
    const data = useStaticQuery(graphql`
        query {
            ourTeamSectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-6.jpg" }) {
                publicURL
            },
            aboutUsOurTeamImage: file(relativePath: { eq: "about-us/about-us-our-team-image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

    let ourteamSectionStyle = {
        background: `url(${data.ourTeamSectionBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={ourteamSectionStyle}>
            <div className="cw-container content-section our-team-section">
                <div className="our-team-section__photo-column">
                    <Img className="photo" fluid={data.aboutUsOurTeamImage.childImageSharp.fluid} />
                </div>
                <div className="our-team-section__content-column">
                    <h2 className="section-header section-header--colored">Our Team</h2>
                    <p className="section-description">
                        Codewave currently employs over 20 talented people with very wide skills in the field of web development, mobile development, cloud architecture, server management, project management, UX, graphic design.
                    </p>
                    <p className="section-description">
                        Our constant focus on Customer satisfaction allows us to maintain great relationships with our Clients for many years. CodeWave’s engineers constantly improve their skill set and grow in experience which combined with our Customer-first approach allows us to successfully compete in a global market.
                    </p>
                    <p className="section-description">
                        We are always looking for new challenges and our customers’ opinion confrms this.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default OurTeamSection