import React, { Component,Children } from "react";
import Slider from "react-slick";
import NextArrow from "../images/svg/next.svg"
import PreviousArrow from "../images/svg/previous.svg"

export default class VerticalTabs extends Component {
  state = {
    slideIndex: 0,
    updateCount: 0
  };

  handleSelection(index, event) {
      if(event) {
        if(["Enter", " "].indexOf(event.key) > -1) {
          event.preventDefault()
        } else {
          return;
        }
      }

      this.slider.slickGoTo(index)
  }

  renderNav() {
    let { children } = this.props;
    if(!children) return <></>

    return Children.map(children, 
      (child, index) => 
        <li tabIndex="0" role="menuitem"  key={index}
            className={`vertical-nav__item ${this.state.slideIndex === index ? 'vertical-nav__item--active' : ''}`}  
            onClick={() => this.handleSelection(index)} 
            onKeyUp={(e) => this.handleSelection(index, e) }>
          <span className="vertical-nav__item-index">{index + 1}</span>{child.props.title}
        </li>
      )
  }
  render() {
    let { children } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 250,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };
    return (
      <div className="vertical-tabs">
        <div className="vertical-tabs__nav-col">
          <ul role="menu" className="vertical-nav">{this.renderNav()}</ul>
        </div>
        <div className="vertical-tabs__content-col">
          <Slider ref={slider => (this.slider = slider)} {...settings}>
            {children}
          </Slider>
          <button
            className="vertical-tabs__nav-btn"
            disabled={this.state.slideIndex === 0}
            onClick={() => {
              this.slider.slickGoTo(this.state.slideIndex - 1)
            }}
          ><PreviousArrow/></button>
          <button
            className="vertical-tabs__nav-btn"
            disabled={this.state.slideIndex === children.length - 1}
            onClick={() => {
              this.slider.slickGoTo(this.state.slideIndex + 1)
            }}
          ><NextArrow/></button>
        </div>
      </div>
    )
  }
}