import React from "react"
import {graphql, useStaticQuery} from "gatsby";

const Timeline = () => {
    const data = useStaticQuery(graphql`
        query {
            timelineSectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-1.svg" }) {
                publicURL
            }
        }
    `)

    let timelineSectionStyle = {
        background: `url(${data.timelineSectionBG.publicURL})`
    }

    return (
        <div className="cw-container-fluid" style={timelineSectionStyle}>
            <div className="cw-container content-section timeline">
                <div className="description-wrapper">
                    <h2 className="section-header section-header--colored">Quality since 2008</h2>
                    <p className="section-description">CodeWave incorporated in 2008. Internet was a different place back then. But for the last 12 years we've been constantly delivering value to our clients and getting recognized among the best.</p>
                </div>
                <div className="milestones-wrapper">
                    <div className="timeline__milestone timeline__milestone--red">
                        <div className="milestone-point"/>
                        <div className="milestone-description">
                            <h3>2020</h3>
                            <p>Still working hard to make a difference :-)</p>
                        </div>
                    </div>
                    <div className="timeline__milestone timeline__milestone--blue-light">
                        <div className="milestone-point"/>
                        <div className="milestone-description">
                            <h3>2019</h3>
                            <ul>
                                <li>Clutch Global Top 1000 Companies</li>
                                <li>Clutch Top B2B Companies Poland 2019</li>
                                <li>ISO 27001 accreditation</li>
                                <li>AWS Select Tier partnership</li>
                            </ul>
                        </div>
                    </div>
                    <div className="timeline__milestone timeline__milestone--blue-dark">
                        <div className="milestone-point"/>
                        <div className="milestone-description">
                            <h3>2018</h3>
                            <ul>
                                <li>Clutch Top B2B Companies Poland 2019</li>
                                <li>Energy UpgradeCalifornia nominated to Webbys</li>
                            </ul>
                        </div>
                    </div>
                    <div className="timeline__milestone timeline__milestone--red">
                        <div className="milestone-point"/>
                        <div className="milestone-description">
                            <h3>2017</h3>
                            <p>Still working hard to make a difference :-)</p>
                        </div>
                    </div>
                    <div className="timeline__milestone timeline__milestone--blue-light">
                        <div className="milestone-point"/>
                        <div className="milestone-description">
                            <h3>2016</h3>
                            <ul>
                                <li>Clutch Global Top 1000 Companies</li>
                                <li>Clutch Top B2B Companies Poland 2019</li>
                                <li>ISO 27001 accreditation</li>
                                <li>AWS Select Tier partnership</li>
                            </ul>
                        </div>
                    </div>
                    <div className="timeline__milestone timeline__milestone--blue-dark">
                        <div className="milestone-point"/>
                        <div className="milestone-description">
                            <h3>2015</h3>
                            <ul>
                                <li>Clutch Top B2B Companies Poland 2019</li>
                                <li>Energy UpgradeCalifornia nominated to Webbys</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Timeline
