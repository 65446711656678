import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image"

const AboutUsOurMissionSection = () => {
    const data = useStaticQuery(graphql`
        query {
            aboutUsOurMissionSectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-6.svg" }) {
                publicURL
            },
            aboutUsOurMissionImage: file(relativePath: { eq: "about-us/about-us-our-mission-image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            aboutUsOurVisionImage: file(relativePath: { eq: "about-us/about-us-our-vision-image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `);

    let aboutUsOurMissionSectionStyle = {
        background: `radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0) 75%, #F9FCFC 100%), url(${data.aboutUsOurMissionSectionBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={aboutUsOurMissionSectionStyle}>
            <div className="cw-container content-section our-mission-section">
                <div className="our-mission-section__image-column">
                    <Img className="photo" fluid={data.aboutUsOurMissionImage.childImageSharp.fluid} />
                </div>
                <div className="our-mission-section__content-column">
                    <h2 className="section-header section-header--colored">Our Mission</h2>
                    <p className="section-description">
                        We believe that transparency and customer engagement on every stage of the project is crucial for project success. We build relationships, based on trust and delivered results.
                    </p>
                </div>
            </div>
            <div className="cw-container content-section our-vision-section">
                <div className="our-vision-section__content-column">
                    <h2 className="section-header section-header--colored">Our Vision</h2>
                    <p className="section-description">
                        Our expertise in scalability, software architecture, cloud systems and database performance combined with the velocity of our teams makes us a  great asset for any serious enterprise.
                    </p>
                </div>
                <div className="our-vision-section__image-column">
                    <Img className="photo" fluid={data.aboutUsOurVisionImage.childImageSharp.fluid} />
                </div>
            </div>
        </section>
    )
}

export default AboutUsOurMissionSection