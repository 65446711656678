import React from "react"

const StatsBar = () => (
    <div className="stats-bar">
        <div className="single-stat">
            <h2 className="single-stat__number">
                10
            </h2>
            <p className="single-stat__description">
                Years in operation
            </p>
        </div>
        <div className="single-stat">
            <h2 className="single-stat__number">
                368
            </h2>
            <p className="single-stat__description">
                Projects delivered
            </p>
        </div>
        <div className="single-stat">
            <h2 className="single-stat__number">
                60%
            </h2>
            <p className="single-stat__description">
                Growth rate
            </p>
        </div>
    </div>
)

export default StatsBar