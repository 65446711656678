import React from "react"
import {useStaticQuery, graphql} from "gatsby";
import VerticalTabs from "../components/vertical-tabs"


const HowWeWrokSection = () => {
    const data = useStaticQuery(graphql`
        query {
            sectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-2.svg" }) {
                publicURL
            }
        }
    `);
     let sectionStyle = {
        backgroundImage: `url(${data.sectionBG.publicURL})`,
        backgroundSize: "contain",
        backgroundPosition: "bottom right"
    }

    let tabsCopy = [
        { 
            title: "Business enquiry", 
            time: "3-5 days", 
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                        dictum, dui id faucibus ultricies, nisi erat malesuada nunc, id
                        pharetra erat leo nec lectus. Praesent porta eu justo at
                        pretium.` 
        },
        { 
            title: "Project Workshop", 
            time: "3-5 days", 
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                        dictum, dui id faucibus ultricies, nisi erat malesuada nunc, id
                        pharetra erat leo nec lectus. Praesent porta eu justo at
                        pretium.` 
        },
        { 
            title: "Project Workshop", 
            time: "3-5 days", 
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                        dictum, dui id faucibus ultricies, nisi erat malesuada nunc, id
                        pharetra erat leo nec lectus. Praesent porta eu justo at
                        pretium.` 
        },
        { 
            title: "Project Workshop", 
            time: "3-5 days", 
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                        dictum, dui id faucibus ultricies, nisi erat malesuada nunc, id
                        pharetra erat leo nec lectus. Praesent porta eu justo at
                        pretium.` 
        }
    ]

    return (
      <section className="cw-container-fluid" style={sectionStyle}>
        <div className="cw-container content-section how-we-work-section">
          <h2 className="section-header section-header--colored">
            How we work
          </h2>
          <p className="section-description">
            Our preferred approach is to use Scrum as a project management
            methodology
          </p>
          <VerticalTabs>
            {tabsCopy.map((copy, index) => <div key={index} className="plain-card" title={copy.title}>
              <div className="plain-card__tag">{copy.time}</div>
              <h3 className="plain-card__header">{copy.title}</h3>
              <p className="plain-card__content">{copy.description}</p>
            </div>)}
          </VerticalTabs>
        </div>
      </section>
    )
}

export default HowWeWrokSection