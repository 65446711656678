import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import AboutUsIntroCTA from "../../components/about-us/about-us-intro-cta";
import Img from "gatsby-image"

const AboutUsIntroSection = () => {
    const data = useStaticQuery(graphql`
        query {
            aboutUsIntroBG: file(relativePath: { eq: "backgrounds/intro-bg.jpg" }) {
                publicURL
            },
            aboutUsIntroImage: file(relativePath: { eq: "about-us/about-us-intro-image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `);

    let AboutUsIntroSectionStyle = {
        backgroundImage: `url(${data.aboutUsIntroBG.publicURL})`
    }

    return (
        <section className="cw-container-fluid" style={AboutUsIntroSectionStyle}>
            <div className="intro intro--about-us">
                <div className="intro__column">
                    <AboutUsIntroCTA/>
                </div>
                <div className="intro__column intro__column--photo">
                    <Img className="photo" fluid={data.aboutUsIntroImage.childImageSharp.fluid} />
                </div>
            </div>
        </section>
    )
}

export default AboutUsIntroSection
