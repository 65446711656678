import React from "react"
import AwardsAndRecognitionsLogotypes from "../components/awards-and-recognitions-logotypes";

const AwardsAndRecognitionSection = () => {

    return (
        <section className="cw-container-fluid">
            <div className="cw-container content-section awards-and-recognition-section">
                <h2 className="section-header section-header--colored">
                    Awards & Recognition
                </h2>
                <p className="section-description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>Aenean suscipit justo at ante congue porttitor.
                </p>
                <AwardsAndRecognitionsLogotypes/>
            </div>
        </section>
    )
}

export default AwardsAndRecognitionSection