import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const AwardsAndRecognitionsLogotypes = () => {
    const data = useStaticQuery(graphql`
        query {
            logo1: file(relativePath: { eq: "partners-logotypes/microsoft-partner-network.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            logo2: file(relativePath: { eq: "partners-logotypes/linux-sysadmin-certified.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            logo3: file(relativePath: { eq: "partners-logotypes/amazon-partner-network.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            logo4: file(relativePath: { eq: "certificates/webby-awards.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            logo5: file(relativePath: { eq: "certificates/clutch-top-2018.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            logo6: file(relativePath: { eq: "certificates/clutch-top-2019.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            logo7: file(relativePath: { eq: "certificates/clutch-top-1000.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, traceSVG: {color: "#35bcd8"}) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <div className="awards-and-recognitions-logotypes">
            <Img className="awards-and-recognitions-logo" fluid={data.logo1.childImageSharp.fluid} />
            <Img className="awards-and-recognitions-logo awards-and-recognitions-logo--square" fluid={data.logo2.childImageSharp.fluid} />
            <Img className="awards-and-recognitions-logo" fluid={data.logo3.childImageSharp.fluid} />
            <Img className="awards-and-recognitions-logo" fluid={data.logo4.childImageSharp.fluid} />
            <Img className="awards-and-recognitions-logo awards-and-recognitions-logo--square" fluid={data.logo5.childImageSharp.fluid} />
            <Img className="awards-and-recognitions-logo awards-and-recognitions-logo--square" fluid={data.logo6.childImageSharp.fluid} />
            <Img className="awards-and-recognitions-logo awards-and-recognitions-logo--square" fluid={data.logo7.childImageSharp.fluid} />
        </div>
    )
}

export default AwardsAndRecognitionsLogotypes
